import http from '@/middleware'

/*const mockedData = [
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        georeference: "Francisco Miguens 180, Santa Fe, Santa Fe, Argentina",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_c4: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    },
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        georeference: "Alem 23, Paraná, Entre Ríos, Argentina",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_c4: "55",
        pressure_c5: "55",
        pressure_c6: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    },
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    },
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_c4: "55",
        pressure_c5: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    },
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_c4: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    },
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_c4: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    },
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_c4: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    },
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_c4: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    },
    { 
        date: "20/02/22",
        time: "10:10:13",
        speed: "60 km/h",
        event: "01",
        communicationTimestamp: "20/02/22 10:10:13",
        odometer: "75847",
        driver: "gomez",
        pressure_c1: "55",
        pressure_c2: "55",
        pressure_c3: "55",
        pressure_c4: "55",
        pressure_in: "30",
        alarm: "2",
        alarmColor: "#e9d8a6"
    }
]*/

export const positionsReportApi = {

    async getCaliperPositionsReportFixedRange (idThing, dateRange, timezone, internationalization) {
        const result = await http.post('/api/positions/caliper/report/fixedRange', { idThing, dateRange, timezone,internationalization});
        return result.data;
    },

    async getCaliperPositionsReportByDate (idThing, timezone, from, to, filters, internationalization) {
        const lang = localStorage.getItem("locale");
        const result = await http.post('/api/positions/caliper/report/customRange', { idThing, timezone, from, to, lang, ...filters, internationalization});
        return result.data;
    }
}