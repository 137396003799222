import reportStorageApi from '@/api/report-storage.api';
import { breadcrumbService } from "@/business/breadcrumb.service";
import { configurationService } from "@/business/configurationService";
import { filterService } from "@/business/filterService";
import { positionsReportService } from "@/business/positionsReportService";
import BreadcrumbComponent from "@/components/commons/breadcrumb/BreadcrumbComponent.vue";
import FilterComponent from '@/components/filter/FilterComponent.vue';
import { SnackbarStyle } from '@/constants/constants';
import i18n from "@/i18n";
import http from "@/middleware";
import store from "@/store/store";
import {getThingNames, getThingIdSelected} from "@/tools/functions";
import { mapActions, mapMutations } from 'vuex';
import eventService from "../../business/eventService";

export default {
    components: {
        BreadcrumbComponent,
        FilterComponent
    },
    name: "PositionsReportComponent",
    data: () => ({
        tabs: [{
            id: 'tab-positions',
            name: i18n.t('positionsReport.breadcrumbTabName')
        }
        ],
        model: {
            table: {
                headers: [],
                customHeaders: [],
                data: []
            }
        },
        tableDataKey: 'number',
        extraInformation: {},
        tableFilterRules: {},
        disableRefreshTable: true,
        loadingTable: false,
        tableConfig: {},
        currentTab: 'tab-positions',
        breadcrumbButtons: [],
        showFilters: false,
        filters: filterService.getFilterModel(),
        maxQuantityLimit: 1,
        orderFilterWorstBestPropertyName: '',
        reportType: null,
        middleware: http,
        reportDetails: "Details",
        getDetails: null,
    }),
    created() {
        this.breadcrumbButtons.push(
            {
                id: 'setting-btn',
                icon: 'settings',
                show: () => { return true },
                disable: () => { return false },
                function: this.selector.bind(this)
            }
        );
    },
    async beforeMount() {
    },
    async mounted() {
        this.loadingTable = true;
        try {
            const reportKey = this.$route.query.key;
            const report = reportKey && await reportStorageApi.getReport(reportKey);
            this.reportType = this.$route.params.type;
            if (report) {
                this.filters = report.filters || filterService.getFilterModel();
                this.setTabThingName();
            } else {
                this.showFilters = true;
                this.loadingTable = false;
            }
            const reportData = report ? report.data : [];
            const result = await positionsReportService.getPositionsReportData(reportData, this.reportType);
            this.model.table.data.cleanAndUpdate(result.tableData);
            this.model.table.headers.cleanAndUpdate(result.tableHeaders);
            this.resetBreadcrumbSelectors();
            breadcrumbService.setSummaryDetailsByFilters(this.filters, 'CALIPER_POSITIONS_REPORT');
            this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "CALIPER_POSITIONS_REPORT");
            this.getDetails = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
        } finally {
            this.loadingTable = false;
        }
        // "cellType" : "EXTRA_INFORMATION",
        // "parameters" : "events"
    },
    beforeDestroy() {
        this.closeReportDefer();
    },
    methods: {
        ...mapActions({
            showSnackbar: "snackbar/showSnackbar"
        }),
        ...mapMutations('reportDefer', {
            showReportDefer: 'showReportDefer',
            closeReportDefer: 'closeReportDefer',
            commitVisible: 'commitVisible'
        }),
        /* eslint-disable no-unused-vars */
        clickRow(data) {
        },
        /**
         * Para cargar la configuración de la tabla
         */
        async getConfig() {
            const config = await configurationService.get('products-position-caliper-report')
            this.tableConfig = config && config.data ? config.data : {}
        },
        /**
         * Para guardar la configuración de la tabla
         * @param {*} config
         */
        saveConfig(config) {
            configurationService.save('products-position-caliper-report', config)
                .then(() => {
                    this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
                    this.getConfig()
                }).catch(() => {
                    this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
                })
        },
        refreshTable() {
        },
        closeFilter() {
            this.showFilters = false;
        },
        selector() {
            this.showFilters = !this.showFilters;
        },
        async saveFilterChanges() {
            this.closeFilter();
            this.cleanData();
            try {
                this.loadingTable = true;
                const result = await positionsReportService.getPositionsReport(this.filters, this.reportType);
                if (result) {
                    this.setReport(result);
                } else {
                    if (this.loadingTable) this.commitVisible({ visible: true })
                    this.showReportDefer({ updateFunction: this.setReportByKey.bind(this) })
                }
            } catch (err) {
                console.log("Error:", err)
            } finally {
                this.loadingTable = false;
            }
        },
        async setReport(thing) {
            this.resetBreadcrumbSelectors();
            if (thing) {
                const thingId = getThingIdSelected(this.filters);
                breadcrumbService.setSummaryDetailsByFilters(this.filters, 'CALIPER_POSITIONS_REPORT');
                this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "CALIPER_POSITIONS_REPORT");
                this.getDetails = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
                if (thingId) {
                    const events = await eventService.getEventsMapNameByThingsIds([thingId])
                    this.extraInformation['events'] = events;
                }
                this.model.table.data.cleanAndUpdate(thing.tableData);
                this.model.table.headers.cleanAndUpdate(thing.tableHeaders);
                this.setTabThingName();
            } else {
                this.cleanData();
                this.showFilters = true;
            }
        },
        async setReportByKey(reportKey) {
            const report = reportKey && await reportStorageApi.getReport(reportKey);
            if (report && report.filters) {
                this.filters = report.filters;
            } else {
                this.filters = filterService.getFilterModel();
            }
            const result = await positionsReportService.getPositionsReportData(report.data || [], this.reportType);
            this.setReport(result);
        },
        cleanData() {
            this.model.table.data.cleanAndUpdate([]);
            breadcrumbService.setSummaryDetailsByFilters(this.filters, 'CALIPER_POSITIONS_REPORT');
            this.reportDetails = breadcrumbService.getFilterDetailsHTML(this.filters, "CALIPER_POSITIONS_REPORT");
            this.getDetails = breadcrumbService.getFilterDetailsHTMLExport(this.filters);
        },
        resetBreadcrumbSelectors() {
            store.dispatch("breadcrumb/cleanBreadcrumb");
        },
        setTabThingName() {
            const thingTab = this.tabs.find(t => t.id == "thing-tab");
            const thingName = this.getThingName(this.filters);
            const updatedTabs = [...this.tabs];
            if (thingTab) {
                updatedTabs[1].name = thingName;
            } else {
                updatedTabs.push(
                    {
                        id: "thing-tab",
                        name: thingName
                    }
                )
            }
            this.tabs.cleanAndUpdate(updatedTabs);
        },
        getThingName(filters) {
            const data = filters.generalData;
            const names = data && data.selectedThings ? getThingNames(data.selectedThings) : "";
            if (names.length > 0) {
                return names[0];
            }
            return "";
        }
    }
}