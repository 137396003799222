import eventApi from "../api/eventApi"

class EventService {
    async getEventsMapNameByThingsIds(ids) {
        const lang = localStorage.getItem('locale')
        const result = {}
        const events = await eventApi.getEventsByThingsIds(ids)
        if (events && events.length > 0) {
            events.forEach((event) => {
                result[event.number] = event.name[lang]
            })
        }
        return result;
    }
}

export default new EventService();