import { positionsReportApi } from "@/api/positions.api";
import { POSITIONS_TYPE } from "@/constants/constants";
import { ALARM_COLOR_TEXT_KEY, CALIPER_POSITIONS_CUSTOM_TABLE_HEADER, CALIPER_POSITIONS_TABLE_HEADERS, CIRCUIT_TEXT_KEY, POSITIONS_REPORT_TYPE } from "@/constants/positions.constant";
import i18n from "@/i18n";
import store from "@/store/store";
import {parseDateWithTimezone, parseTimeWithTimezone, processFilters} from "@/tools/functions";
import { TimeRanges } from '@colven/common-domain-lib/lib';
import { PRESSURE_IN_COLOR } from "@/constants/positions.constant";

export const positionsReportService = (function () {
    'use strict';

    async function getPositionsReportData(reportData, type) {
        const headers = [];
        if (type === POSITIONS_TYPE.CALIPER) {
            headers.cleanAndUpdate(CALIPER_POSITIONS_TABLE_HEADERS);
            if (reportData && reportData.circuitsEnabled) {
                const circuits = reportData.circuitsEnabled;
                circuits.forEach(cKey => {
                    const customHeader = CALIPER_POSITIONS_CUSTOM_TABLE_HEADER;
                    customHeader.text = i18n.t('positionsReport.headers.pressure_' + CIRCUIT_TEXT_KEY[cKey]);
                    customHeader.cellConfig = {
                        color: 'alarmColor' + ALARM_COLOR_TEXT_KEY[cKey]
                    }
                    customHeader.value = 'pressure_' + CIRCUIT_TEXT_KEY[cKey];
                    headers.push(Object.assign({}, customHeader));
                })
            }
        }
        const internationalization = store.getters['user/getInternationalization'];
        if (reportData.positions) {
            reportData.positions.forEach(data => {
                if (data.trackTimestamp) {
                    data.time = parseTimeWithTimezone(data.trackTimestamp, internationalization.date);
                    data.date = parseDateWithTimezone(data.trackTimestamp, internationalization.dateMask);
                }
            });
        }
        return {
            tableData: reportData.positions,
            tableHeaders: headers
        }
    }

    async function getPositionsReport(filters, type) {
        const headers = [];
        const tableData = [];
        const filterData = processFilters(filters);
        const timezone = store.getters["user/getEnterpriseTimezone"];
        const idThing = filterData.idThings[0] || null;
        if (type === POSITIONS_TYPE.CALIPER) {
            filterData.reportType = POSITIONS_REPORT_TYPE.CALIPER;
            headers.cleanAndUpdate(CALIPER_POSITIONS_TABLE_HEADERS);
            if (filterData.reporFixedKey === TimeRanges.CUSTOM) {
                filterData.originalFilters = filters;
                await positionsReportApi.getCaliperPositionsReportByDate(idThing, timezone, filterData.from, filterData.to, filterData, store.getters['user/getInternationalization']);
                return null;
            } else {
                const reportData = await positionsReportApi.getCaliperPositionsReportFixedRange(idThing, filterData.reporFixedKey, timezone,store.getters['user/getInternationalization']);
                processPositions(reportData.positions);
                tableData.cleanAndUpdate(reportData.positions);
                const circuits = reportData.circuitsEnabled;
                circuits.forEach(cKey => {
                    const customHeader = CALIPER_POSITIONS_CUSTOM_TABLE_HEADER;
                    customHeader.text = i18n.t('positionsReport.headers.pressure_' + CIRCUIT_TEXT_KEY[cKey]);
                    customHeader.cellConfig = {
                        color: 'alarmColor' + ALARM_COLOR_TEXT_KEY[cKey]
                    }
                    customHeader.value = 'pressure_' + CIRCUIT_TEXT_KEY[cKey];
                    headers.push(Object.assign({}, customHeader));
                })
            }
        }
        return {
            tableData: tableData,
            tableHeaders: headers
        }
    }

    function processPositions(positions) {
        if (positions && positions.length > 0) {
            positions.forEach((position) => {
                position.pressureInColor = PRESSURE_IN_COLOR;
            });
        }
    }

    return {
        getPositionsReport,
        getPositionsReportData
    }
})();