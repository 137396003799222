import { DataType, FilterType, CellType } from '@colven/common-domain-lib/lib';
import i18n from "@/i18n";

export const CALIPER_POSITIONS_TABLE_HEADERS = [
  {
    text: i18n.t('positionsReport.headers.date'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'date'
  },
  {
    text: i18n.t('positionsReport.headers.hour'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'time'
  },
  {
    text: i18n.t('positionsReport.headers.speed'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'speed',
  },
  {
    text: i18n.t('positionsReport.headers.event'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'eventNumber',
    "cellType": "EXTRA_INFORMATION",
    "parameters": "events"
  },
  {
    text: i18n.t('positionsReport.headers.georeference'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'georeference',
    width: 220
  },
  {
    text: i18n.t('positionsReport.headers.communicationTimestamp'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'communicationTimestamp'
  },
  {
    text: i18n.t('positionsReport.headers.odometer'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'odometer'
  },
  {
    text: i18n.t('positionsReport.headers.driver'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'driver'
  },
  {
    text: i18n.t('positionsReport.headers.pressure_in'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    cellType: CellType.textBackgroundColor,
    cellConfig: {
      color: 'pressureInColor'
    },
    value: 'pressure_in'
  }
];

export const CALIPER_POSITIONS_CUSTOM_TABLE_HEADER = {
  text: i18n.t('positionsReport.headers.pressure_c1'),
  align: 'left',
  filterType: FilterType.textField,
  selected: true,
  sortable: true,
  type: DataType.string,
  cellType: CellType.textBackgroundColor,
  cellConfig: {
    color: 'alarmColor'
  },
  value: 'pressure_c1'
}

export const CIRCUIT_TEXT_KEY = {
  "01": "c1",
  "02": "c2",
  "03": "c3",
  "04": "c4",
  "05": "c5",
  "06": "c6"
}

export const ALARM_COLOR_TEXT_KEY = {
  "01": "P1",
  "02": "P2",
  "03": "P3",
  "04": "P4",
  "05": "P5",
  "06": "P6"
}

export const POSITIONS_REPORT_TYPE = {
  CALIPER: "caliper_positions_report"
}

export const PRESSURE_IN_COLOR = "#E4E4E4";